import React from "react"

// Components
import Header from "./Header"

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      <div className="container">{children}</div>
    </div>
  )
}

export default Layout
