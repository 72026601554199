import React from 'react'
import moment from 'moment'
import { Row, Col } from 'reactstrap'
import './Presenze.css'

const renderGiornoSettimana = data => {
  switch (
    moment(data)
      .format('ddd')
      .toLowerCase()
  ) {
    case 'mon':
      return 'LUN'
    case 'tue':
      return 'MAR'
    case 'wed':
      return 'MER'
    case 'thu':
      return 'GIO'
    case 'fri':
      return 'VEN'
    case 'sat':
      return 'SAB'
    case 'sun':
      return 'DOM'
  }
}

const Presenze = ({ presenze }) => {
  const totali = presenze.reduce(
    (acc, giorno) => {
      return {
        ...acc,
        [giorno.tipo]: acc[giorno.tipo] + 1,
      }
    },
    {
      P: 0,
      A: 0,
      M: 0,
      B: 0,
    },
  )

  return (
    <div className="presenze">
      <Row>
        <Col lg="8" xl="7">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Giorno</th>
                  <th>Giorno sett</th>
                  <th>Presente</th>
                  <th>Assente</th>
                  <th>In bianco</th>
                  <th>Mini pasto</th>
                </tr>
              </thead>
              <tbody>
                {presenze.map(presenza => (
                  <tr key={presenza.id}>
                    <td className="giorno">
                      {moment(presenza.data).format('D')}
                    </td>
                    <td>{renderGiornoSettimana(presenza.data)}</td>
                    <td>{presenza.tipo === 'P' && '×'}</td>
                    <td>{presenza.tipo === 'A' && '×'}</td>
                    <td>{presenza.tipo === 'B' && '×'}</td>
                    <td>{presenza.tipo === 'M' && '×'}</td>
                  </tr>
                ))}
                <tr className="totali">
                  <td colSpan="2">TOTALI</td>
                  <td>{totali.P}</td>
                  <td>{totali.A}</td>
                  <td>{totali.B}</td>
                  <td>{totali.M}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Presenze
