import { gql } from "apollo-boost";

const GET_BOLLETTE = gql`
  query bollette($anno: Int!) {
    bollette(limit: 100, where: { anno: $anno }, orderBy: [mese_ASC, bambinoCognome_ASC, bambinoNome_ASC]) {
      nodes {
        id
        mese
        anno
        bambinoCognome
        bambinoNome
        scuolaNome
        numeroBolletta
        importoTotale
        dataScadenza
        importoPagato
        dataPagamento
      }
    }
  }
`;

export default GET_BOLLETTE;
