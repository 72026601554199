import React, { useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import GET_BOLLETTA from '../graphql/getBolletta'
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import './DettaglioBolletta.css'

// Components
import Layout from '../Layout/Layout'
import AvvisoPagamento from './AvvisoPagamento/AvvisoPagamento'
import Presenze from './Prezenze/Presenze'
import moment from 'moment'

const DettaglioBolletta = ({ match }) => {
  const { data, loading, error } = useQuery(GET_BOLLETTA, {
    variables: { id: parseInt(match.params.id) },
  })

  const [activeTab, setActiveTab] = useState('avviso')

  if (loading) return <div>Caricamento bolletta...</div>
  if (error || !data.bolletta) return <div>Errore caricamento bolletta</div>

  const { bolletta } = data

  const renderMese = numMese => {
    switch (numMese) {
      case 1:
        return 'Gennaio'
      case 2:
        return 'Febbraio'
      case 3:
        return 'Marzo'
      case 4:
        return 'Aprile'
      case 5:
        return 'Maggio'
      case 6:
        return 'Giugno'
      case 7:
        return 'Luglio'
      case 8:
        return 'Agosto'
      case 9:
        return 'Settembre'
      case 10:
        return 'Ottobre'
      case 11:
        return 'Novembre'
      case 12:
        return 'Dicembre'
    }
  }

  const renderAnno = anno => {
    return moment()
      .year(anno)
      .format('YYYY')
  }

  return (
    <Layout>
      <div className="dettaglio-bolletta">
        <Container>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'avviso' ? 'active' : undefined}
                onClick={() => {
                  setActiveTab('avviso')
                }}
              >
                Avviso di pagamento
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'presenze' ? 'active' : undefined}
                onClick={() => {
                  setActiveTab('presenze')
                }}
              >
                Presenze mese {renderMese(bolletta.mese)}{' '}
                {renderAnno(bolletta.anno)}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="avviso">
              <AvvisoPagamento bolletta={bolletta} />
            </TabPane>
            <TabPane tabId="presenze">
              <Presenze presenze={bolletta.presenze} />
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </Layout>
  )
}

export default DettaglioBolletta
