import React, { Fragment, useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import {
  Navbar,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import GET_USER from '../graphql/getUser'
import './Header.css'

const Header = () => {
  const { data, loading, error } = useQuery(GET_USER)
  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const handleToggleUserMenuOpen = () => {
    setUserMenuOpen(!userMenuOpen)
  }

  const handleLogout = () => {
    localStorage.clear()
    window.location = 'https://www.comune.bergamo.it/'
  }

  return (
    <header>
      <Navbar light expand="md">
        <div className="container">
          <NavbarBrand tag={Link} to="/">
            Comune di Bergamo
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            <Dropdown isOpen={userMenuOpen} toggle={handleToggleUserMenuOpen}>
              <DropdownToggle nav caret>
                {data.me && data.me.nome}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={handleLogout}>
                  Esci
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
