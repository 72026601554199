import { gql } from 'apollo-boost'

const GET_USER = gql`
  query me {
    me {
      nome
      ruolo
    }
  }
`

export default GET_USER