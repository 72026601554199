import React from 'react'
import { Alert } from 'reactstrap'
import moment from 'moment'
import Importo from '../../utilities/Importo'
import './StatoPagamento.css'

const StatoPagamento = ({ bolletta }) => {

  let statusPagamento = (
    <Alert className="stato-pagamento">
      <p className="mb-0">
        Pagato <Importo importo={bolletta.importoPagato} /> il{' '}
        {moment(bolletta.dataPagamento).format('DD/MM/YYYY')}
      </p>
    </Alert>
  )

  if (!bolletta.dataPagamento || !bolletta.importoPagato) {
    if (moment(bolletta.dataScadenza).isBefore(moment())) {
      statusPagamento = (
        
        <Alert color="danger" className="stato-pagamento">
          <p className="mb-0">Scaduto</p>
        </Alert>
      )
      return statusPagamento
    }
    statusPagamento = (
      <Alert color="danger" className="stato-pagamento">
        <p className="mb-0">
          Da pagare entro il{' '}
          {moment(bolletta.dataScadenza).format('DD/MM/YYYY')}
        </p>
      </Alert>
    )

    return statusPagamento
  }

  if (bolletta.importoPagato < bolletta.importoTotale) {
    statusPagamento = (
      <Alert color="danger" className="stato-pagamento">
        <p>
          Pagato <Importo importo={bolletta.importoPagato} /> il{' '}
          {moment(bolletta.dataPagamento).format('DD/MM/YYYY')}
        </p>
        <p className="mb-0">
          <span>ATTENZIONE:</span> mancano{' '}
          <Importo
            importo={bolletta.importoTotale - bolletta.importoPagato}
          />
        </p>
      </Alert>
    )
    return statusPagamento
  }

  return statusPagamento
}

export default StatoPagamento
