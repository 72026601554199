import React from 'react'
import { Row, Col } from 'reactstrap'
import './AvvisoPagamento.css'

// Components
import Importo from '../../utilities/Importo'
import StatoPagamento from '../StatoPagamento/StatoPagamento'
import moment from 'moment'

const AvvisoPagamento = ({ bolletta }) => {
  const cifraGiornoCf = bolletta.pagatoreCodiceFiscale.slice(10, 11)
  const sesso = parseInt(cifraGiornoCf, 10) >= 4 ? 'F' : 'M'
  const [abi, cab] = [bolletta.iban.slice(5, 10), bolletta.iban.slice(10, 15)]

  return (
    <div className="avviso-pagamento">
      <Row>
        <Col md="6">
          <div>
            <h2>
              Avviso n. {bolletta.numeroBolletta + ' '}
              {moment(bolletta.dataEmissione).format('DD/MM/YYYY')}
            </h2>
            <div className="bolletta-servizio">
              <p className="mb-0">
                Ristorazione scolastica
                <br />
                <b>
                  {bolletta.bambinoCognome} {bolletta.bambinoNome}
                </b>
                <br />
                Istituto {bolletta.scuolaNome} {bolletta.sezioneClasse}{' '}
                {bolletta.sezioneSezione}
                <br />
                Periodo: {bolletta.periodo}
              </p>
            </div>

            <table>
              <tbody>
                <tr>
                  <td>Importo:</td>
                  <td className="value">
                    <Importo importo={bolletta.importoTotale} />
                  </td>
                </tr>
                <tr>
                  <td>Scadenza:</td>
                  <td className="value">
                    {moment(bolletta.dataScadenza).format('DD/MM/YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col md={{ size: 5, offset: 1 }}>
          <address>
            {sesso === 'F' ? 'Gent.ma Sig.ra' : 'Egr. Sig.'} <br />
            {bolletta.pagatoreCognome} {bolletta.pagatoreNome} <br />
            {bolletta.pagatoreIndirizzo} <br />
            {bolletta.pagatoreCap} {bolletta.pagatoreComune} <br />
            {'C.F. ' + bolletta.pagatoreCodiceFiscale} <br />
          </address>
          <StatoPagamento bolletta={bolletta} />
          <div>
            {bolletta.tipoPagamento === 'RID' ? (
              <p>
                Addebito sul conto corrente presso banca
                <br />
                ABI {abi} CAB {cab}
              </p>
            ) : (
              <React.Fragment>
                <p>
                  Per cassa presso UBI BANCA S.C.P.A. oppure con bonifico
                  bancario a favore di Comune di Bergamo - UBI BANCA S.C.P.A.
                  - Ag. di Bergamo
                  <br />
                  IBAN IT11Q0311111101000000050084
                </p>
                <p>
                  <b>
                    E' OBBLIGATORIO indicare la seguente causale: <br />
                    CODICE {bolletta.codiceBarre} - {bolletta.bambinoCognome}{' '}
                    {bolletta.bambinoNome}
                  </b>
                </p>
              </React.Fragment>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AvvisoPagamento
