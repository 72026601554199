import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import GET_ANNI from '../graphql/getAnni'
import moment from 'moment'

import './SelectAnno.css'

const SelectAnno = ({ anno, onChange }) => {
  const { data, loading, error } = useQuery(GET_ANNI)

  if (loading) return <div>Caricamento anni...</div>
  if (error) return <div>Errore caricamento anni</div>

  const annoCorrente = moment().year()

  const annoCorrentePresente = !!data.anniBollette.find(a => a === annoCorrente)

  return (
    <div className="select-anno">
      <label className="label-year" htmlFor="year">
        Seleziona anno
      </label>
      <select
        className="form-control"
        id="year"
        value={anno}
        onChange={e => onChange(parseInt(e.target.value))}
      >
        {data.anniBollette.map(a => (
          <option key={a} value={a}>
            {a}
          </option>
        ))}
        {!annoCorrentePresente && (
          <option key={annoCorrente} value={annoCorrente}>
            {annoCorrente}
          </option>
        )}
      </select>
    </div>
  )
}

export default SelectAnno
