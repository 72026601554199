import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "apollo-boost"
import { ApolloProvider } from "react-apollo-hooks"
import qs from "qs"

import { GRAPHQL_API_URI } from "./config"
import ElencoBollette from "./ElencoBollette/ElencoBollette"
import DettaglioBolletta from "./DettaglioBolletta/DettaglioBolletta"
import Layout from "./Layout/Layout"

import "./App.css"

// Get token from querystring
const parsedQuery = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
})
const token = parsedQuery.t

if (token) {
  // Set token to localStorage (used by queries)
  localStorage.setItem("auth_token", token)
}

const httpLink = new HttpLink({ uri: GRAPHQL_API_URI })

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('auth_token')

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  // Call the next link in the middleware chain.
  return forward(operation)
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

const App = props => {
  return (
    <div className="app">
      <ApolloProvider client={client}>
        <Router>
          <Route path="/" exact component={ElencoBollette} />
          <Route path="/avviso/:id" component={DettaglioBolletta} />
        </Router>
      </ApolloProvider>
    </div>
  )
}

export default App
