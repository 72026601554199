import { gql } from 'apollo-boost'

const GET_BOLLETTA = gql`
  query bolletta($id: Int!) {
    bolletta(where: {id: $id}) {
      id
      numeroBolletta
      codiceBarre
      dataEmissione
      dataScadenza
      anno
      mese
      periodo
      servizio
      scuolaNome
      sezioneClasse
      sezioneSezione
      bambinoCodice
      bambinoCognome
      bambinoNome
      bambinoCodiceFiscale
      pagatoreCognome
      pagatoreNome
      pagatoreCodiceFiscale
      pagatoreIndirizzo
      pagatoreCap
      pagatoreComune
      pagatoreProvincia
      importoTotale
      importoPagato
      dataPagamento
      tipoPagamento
      iban
      presenze {
        id
        data
        tipo
      }
    }
  }
`

export default GET_BOLLETTA