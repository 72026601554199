import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import GET_BOLLETTE from '../graphql/getBollette'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './TabellaBollette.css'

// Components
import Importo from '../utilities/Importo'

const getStyle = totaleDaPagare => ({
  color: totaleDaPagare > 0 ? '#f44366' : '#8bc34a',
  fontWeight: 'bold',
})

const Bollette = ({ anno }) => {
  const { data, loading, error } = useQuery(GET_BOLLETTE, {
    variables: { anno },
  })

  const totaleDaPagare = data.bollette
    ? data.bollette.nodes.reduce(
        (totale, bolletta) =>
          totale + bolletta.importoTotale - bolletta.importoPagato,
        0,
      )
    : 0

  if (loading) return <div>Caricamento bollette {anno}...</div>
  if (error) return <div>Errore caricamento bollette</div>

  return (
    <div className="tabella-bollette">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Mese</th>
              <th>Bambino</th>
              <th>Bolletta&nbsp;N°</th>
              <th>Scadenza</th>
              <th>Importo</th>
              <th>Pagato</th>
              <th>Da pagare</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <React.Fragment>
              {data.bollette.nodes.map(bolletta => (
                <tr key={bolletta.id}>
                  <td>
                    {bolletta.mese} / {bolletta.anno}
                  </td>
                  <td>
                    {bolletta.bambinoCognome} {bolletta.bambinoNome}
                    <p>({bolletta.scuolaNome})</p>
                  </td>
                  <td>{bolletta.numeroBolletta}</td>
                  <td>{moment(bolletta.dataScadenza).format('DD/MM/YYYY')}</td>
                  <td>
                    <Importo importo={bolletta.importoTotale} />
                  </td>
                  <td>
                    <Importo importo={bolletta.importoPagato} />
                  </td>
                  <td>
                    <Importo
                      importo={bolletta.importoTotale - bolletta.importoPagato}
                      style={getStyle(bolletta.importoTotale - bolletta.importoPagato)}
                    />
                  </td>
                  <td>
                    <Link
                      className="btn btn-primary"
                      to={`/avviso/${bolletta.id}`}
                    >
                      Dettagli
                    </Link>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  Totale da pagare
                </td>
                <td>
                  <Importo
                    importo={totaleDaPagare}
                    style={getStyle(totaleDaPagare)}
                  />
                </td>
              </tr>
            </React.Fragment>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Bollette
