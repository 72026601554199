import React, { useState } from 'react'
import moment from 'moment'

import SelectAnno from './SelectAnno'
import TabellaBollette from './TabellaBollette'
import Layout from '../Layout/Layout'

const ElencoBollette = () => {
  const annoCorrente = moment().year()
  const [anno, setAnno] = useState(annoCorrente)

  return (
    <div className="elenco-bollette">
      <Layout>
        <div className="filtri">
          <SelectAnno anno={anno} onChange={setAnno} />
        </div>
        <TabellaBollette anno={anno} />
      </Layout>
    </div>
  )
}

export default ElencoBollette
